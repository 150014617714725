import React, { useEffect, useState } from 'react'

import { gql } from '@apollo/client';

import PhotoGrid from '../PhotoGrid/PhotoGrid';
import ApolloFilter from '../ApolloFilter/ApolloFilter';

export default function VideosFilter ( props ) {

	const { filters, defaultTag } = props

	const [ photoTag, setPhotoTag ] = useState(defaultTag?.id ? defaultTag.id : [])
	const [ filterState, setFilterState ] = useState({
		photo_tags: {
			id: photoTag
		},
	})

	const productQuery = gql`
		query getPhotos ($start: Int, $limit: Int, $sort: String, $where: JSON) {
			photos ( start: $start, limit: $limit, sort: $sort, where: $where) {
				image {
					url
					formats
					width
					height
				}
			}
		}
	`;

	useEffect(() => {
		setFilterState( {
			photo_tags : {
				id: photoTag
			}
		})
	}, [ photoTag ])
	
	return (
		<ApolloFilter productQuery={ productQuery } tag={ photoTag } setTag={ setPhotoTag } filterState={ filterState } filters={ filters } >
			<PhotoGrid />
		</ApolloFilter>
	)
}