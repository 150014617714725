import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'
import PhotosFilter from '../components/PhotosFilter/PhotosFilter'

export default function PhotographyPage({ data: { strapi: { photographyPage, photoTags } }, location }) {

	return (
		<>
			<Seo title="Photography &amp; Retouching" description={ photographyPage.seoDescription } pathname={ location.pathname } />

			{ photographyPage && photographyPage.content &&
				<DynamicZone components={ photographyPage.content } />
			}

			<PhotosFilter filters={ photoTags } defaultTag={ photographyPage.photo_tag } />
		</>
	)
}

export const query = graphql`
	query photographyPageQuery {
		strapi {
			photographyPage {
				seoDescription
				photo_tag {
					id
					tag
				}
				content {
					__typename
					... on StrapiQuery_ComponentServicesServiceHero {
						id
						serviceDescription
						serviceTags
						serviceTitle
						serviceImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesVideoHero {
						id
						videoHeroNumber
						videoHeroTitle
						videoHeroImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesFeaturedGallery {
						id
						photo_gallery {
							galleryDescription
							id
							galleryTitle
							galleryImages {
								url
								imageFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
					}
				}
			}
			photoTags {
				id
				tag
			}
		}
	}
`