import React from 'react';

import Photo from './Photo'

const PhotoGrid = ( props ) => {

	return (
		<div className="photo-grid">
			{ props.content.map( ( photo, index ) => (
				<Photo photo={ photo } key={ index } />
			))}
		</div>
	)
}

export default PhotoGrid