import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import FullScreen from '../FullScreen/FullScreen';

import { toggleBodyLock } from '../../helpers/bodyLock'

const PhotoGrid = ( props ) => {

	const { photo } = props
	const [ show, setShow ] = useState( false )

	const toggleClick = () => {
		toggleBodyLock( true )
		setShow( true )
	}

	return (
		<>
			<button className="photo-grid__grid-item" onClick={ () => toggleClick() } >
				<GatsbyImage image={ photo.image.imageFile.childImageSharp.gatsbyImageData } className="photo-grid__image" alt="" />
			</button>
			{ show &&
				<FullScreen setPlay={ setShow }>
					<GatsbyImage image={ photo.image.imageFile.childImageSharp.gatsbyImageData } className="photo-grid__image" objectFit="contain" alt="" />
				</FullScreen>
			}
		</>
	)
}

export default PhotoGrid